import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import GlobalStyles from "components/styles/GlobalStyles"
import CommonHead from "components/utils/CommonHead"
import { NextPage } from "next"
import { AppProps } from "next/dist/shared/lib/router/router"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { ReactElement, ReactNode } from "react"
import { ToastProvider } from "react-toast-notifications"
import { secrets } from "secrets"
import { ThemeProvider } from "styled-components"
import { mainTheme } from "styles/styledComponentsTheme"
import { useInitialize } from "util-functions/init"
import "../styles/globals.css"

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  const apiHost = process.env.NODE_ENV === "development" ? "http://localhost:3000/ingest" : secrets.posthog.apiHost

  posthog.init(secrets.posthog.key, {
    api_host: apiHost,
    ui_host: secrets.posthog.uiHost,
    // Enable debug mode in development
    loaded: posthog => {
      if (process.env.NODE_ENV === "development") posthog.debug()
    },
  })
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useInitialize()

  const getLayout = Component.getLayout ?? (page => page)
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <PostHogProvider client={posthog}>
        <CommonHead />
        <GlobalStyles />
        <ToastProvider placement="top-center">
          <ThemeProvider theme={mainTheme}>{getLayout(<Component {...pageProps} />)}</ThemeProvider>
        </ToastProvider>
      </PostHogProvider>
    </QueryClientProvider>
  )
}

export default MyApp
