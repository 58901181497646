import { getDocument, useDocument } from "hooks/firestore/core/useDocument"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getUserRef } from "hooks/firestore/getRefs"
import { useUserId } from "hooks/localstate/auth/useUserId"
import User from "types/firestore/user"

// ts-prune-ignore-next
export function useUser(): WithRef<User> | undefined
export function useUser(userId: string): WithRef<User> | undefined
export function useUser(userId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realUserId = userId || useUserId()
  const { document } = useDocument(getUserRef(realUserId))
  return document
}

// ts-prune-ignore-next
export async function getUser(userId: string) {
  return await getDocument(getUserRef(userId))
}
