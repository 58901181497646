const primary = "#099934"
const secondary = "#7bc14d"

export const mainTheme = {
  dark: "#3c3c3c",
  basegrey: "#3c3c3c",
  primary: primary,
  secondary: secondary,
  offwhite: "#F5F6F8",
  white: "#FFFFFF",
  yellow: "#ffe179",
  success: "#7bc14d",
  warning: "#ff481f",
  danger: "#C4173C",
  grey: "#e1e5eb",
  lightgrey: "#818ea3",
  lightergrey: "#f2f1f1",
  lightBlueGrey: "#f6f9fa",
  darkgrey: "#808080",
  darkergrey: "#2d2d2d",
  light: "#FCFBFC",
  greyBorder: "#dee2e6",
  brown: "#996418",

  // Shadows
  smallShadow: "0 0.2rem 0.5rem rgba(0, 0, 0, 0.15)",
  shadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
  bigShadow: "0 1rem 1rem rgba(0, 0, 0, 0.15)",
  largeShadow: "2px 3px 10px rgba(0, 0, 0, 0.5)",

  // Breakpoints
  breakpoints: {
    small: "576px",
    medium: "768px",
    large: "992px",
    extraLarge: "1200px",
  },
  gradient: `linear-gradient(160deg, ${primary} 0%, ${secondary} 100%)`,
  reactDatepicker: {
    fontFamily: "neuzeit-grotesk",
    datepickerZIndex: 30,
    colors: {
      accessibility: primary,
      selectedDay: primary,
      selectedDayHover: primary,
      primaryColor: primary,
    },
  },
}
