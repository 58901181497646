import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  WithFieldValue,
} from "firebase/firestore"

// ts-prune-ignore-next
export interface FirestoreDocument<T> {
  _ref: DocumentReference<T>
}

// ts-prune-ignore-next
export type WithRef<T extends DocumentData> = T & FirestoreDocument<T>

// ts-prune-ignore-next
export const genericConverter = <T extends DocumentData>(): FirestoreDataConverter<WithRef<T>> => ({
  toFirestore(data: WithFieldValue<WithRef<T>> | PartialWithFieldValue<WithRef<T>>): DocumentData {
    const result: DocumentData = { ...data }
    delete result._ref
    return result
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<T>): WithRef<T> {
    const data = snapshot.data()
    return { ...data, _ref: snapshot.ref.withConverter(genericConverter<T>()) } as WithRef<T>
  },
})
